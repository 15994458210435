



























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import { IContactData } from '@/constant/models'
import { IDictData, ICustomerData } from '@/api/types'
import ArrayUtil from '@/utils/array'
import AppService from '@/services/app'
import Constant from '@/constant/constant'
import PermissionConst from '@/constant/permissions'
import { UserModule } from '@/store/modules/user'
import WecomUtil from '@/utils/wecom'
import ObjectUtil from '@/utils/object'
import Clipboard from 'clipboard'
import CommonUtil from '@/utils/common'
import { WebCallApi } from '@/proxy'

@Component({
  name: 'MobileContactInput',
  components: { ListTile }
})
export default class extends Vue {
  @Prop() private clueId!: string
  @Prop({ required: true }) private contact!: IContactData
  @Prop({ default: false }) private lookUp!: boolean
  @Prop({ default: true }) private usePadding!: boolean
  @Prop({
    default: function() {
      return {
        mobile: '',
        contactWays: []
      }
    }
  }) private customerInfo!: ICustomerData

  @Prop({ default: '' }) private index!: number
  private contactMethods: IDictData[] = []
  private localContact !: IContactData

  @Watch('contact', { immediate: true })
  private onContactChange(contact: IContactData) {
    this.localContact = contact
  }

  private get isMobile() {
    return this.contact.methodName === '手机号'
  }

  private get showEdit() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.EditContactWay)
  }

  created() {
    this.contactMethods = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
  }

  private get hasMergePermission() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.Merge)
  }

  private onEditClick() {
    this.localContact.isEdit = true
  }

  private cancelEdit() {
    if (this.localContact.isNew) {
      this.$emit('cancel')
      return
    }
    this.localContact.isEdit = false
    this.localContact = this.contact
  }

  private onInputValueChange() {
    const method: IDictData | undefined = this.contactMethods.find(dict => {
      return dict.id === this.localContact.dictId
    })
    if (!method) {
      return
    }
    this.localContact.methodName = method?.name
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const validator: Function = Constant.formRules[method.name]
    validator(null, this.contact.value, (response: any) => {
      this.localContact.error = response?.message
    })
  }

  private deleteContact() {
    this.$emit('delete')
  }

  private saveContact() {
    this.localContact.isEdit = false
    this.$emit('save', this.localContact)
  }

  private async call() {
    const { data } = await new WebCallApi().apiAppWebCallCallPost({
      customerId: this.customerInfo.id,
      customerMobile: this.contact.value,
      clueId: this.clueId
    })
    if (!data?.isOk) {
      this.$message.error(data.message)
    }
  }

  private async addCustomerWechat(event: MouseEvent) {
    if (!CommonUtil.mobile()) {
      this.$message.warning('受企业微信限制，本功能只能在企业微信手机端使用')
      return
    }
    const input = document.createElement('input')
    const clipboard = new Clipboard(input, {
      text: () => this.localContact.value
    })
    clipboard.on('success', async() => {
      this.$message.success('已复制客户手机号到剪切板')
      clipboard.destroy()
      await WecomUtil.instance.navigateToAddCustomer({
        fail: (res) => {
          this.$message.error(ObjectUtil.toString(res))
        }
      })
    })
    clipboard.on('error', () => {
      this.$message.error('复制客户手机号失败')
      clipboard.destroy()
    });
    (clipboard as any).onClick(event)
  }

  private showMergePhoneDrawer(index: number) {
    this.$emit('onMergeDrawerShow', index)
  }
}
