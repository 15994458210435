
























import { Vue, Component, Prop } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import { IProductData } from '@/api/types'
import MarketingApi from '@/api/marketing'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'

@Component({
  name: 'SelectProductDrawer',
  components: {
    MobileProductItem,
    DrawerHeader
  }
})
export default class extends Vue {
  @Prop({ default: false }) private visible!: boolean
  private productList: IProductData[] = []

  created() {
    this.getProductList()
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }

  private async getProductList() {
    const { data } = await MarketingApi.productList()
    this.productList = data
  }

  private selectProduct(product: IProductData) {
    this.$emit('productSelect', product)
    this.close()
  }
}
