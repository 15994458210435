
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { IProductData } from '@/api/types'
import NumberUtil from '@/utils/number'

@Component({
  name: 'MobileProductItem'
})
export default class extends Vue {
  @Prop({ default: true }) private showPrice!: boolean
  @Prop({ default: false }) private usePadding!: boolean
  @Prop({ default: false }) private separate!: boolean
  @Prop({
    default: function() {
      return {
        name: '商品名称',
        price: 0
      }
    }
  }) private data!: IProductData

  get price() {
    return NumberUtil.parseFloat(this.data.price)
  }

  private onItemClick() {
    this.$emit('click', this.data)
  }
}
