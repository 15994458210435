









































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import Divider from '@/components/Divider/index.vue'
import ListTile from '@/components/ListTile/index.vue'
import { IContactData } from '@/constant/models'
import TagGroup from '@/components/TagGroup/index.vue'
import { IClueData, IDictData, IFeiyuClueData, ITagData } from '@/api/types'
import ArrayUtil from '@/utils/array'
import AppService from '@/services/app'
import Constant, { TagType } from '@/constant/constant'
import CustomerApi from '@/api/customer'
import TextUtil from '@/utils/text'
import TagSettingsDrawer from '@/views/wecom/mobile-entry/drawers/tag-settings/index.vue'
import SystemApi from '@/api/system'
import MobileContactInput from '@/views/wecom/mobile-entry/components/ContactInput/index.vue'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import InvalidOrderDrawer from '@/views/wecom/mobile-entry/drawers/invalid-order/index.vue'
import LoseOrderDrawer from '@/views/wecom/mobile-entry/drawers/lose-order/index.vue'
import SuccessOrderDrawer from '@/views/wecom/mobile-entry/drawers/success-order/index.vue'
import CreateOrderDrawer from '@/views/wecom/mobile-entry/drawers/create-order/index.vue'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import LensSpan from '@/components/LensSpan/index.vue'
import FollowClueDrawer from '@/views/wecom/mobile-entry/drawers/follow-clue/index.vue'
import { UserModule } from '@/store/modules/user'
import WecomUtil from '@/utils/wecom'
import ObjectUtil from '@/utils/object'
import PermissionConst from '@/constant/permissions'
import DateUtil from '@/utils/date'

@Component({
  name: 'ClueDetailDrawer',
  components: {
    FollowClueDrawer,
    LensSpan,
    CreateOrderDrawer,
    SuccessOrderDrawer,
    LoseOrderDrawer,
    InvalidOrderDrawer,
    MobileProductItem,
    MobileContactInput,
    TagSettingsDrawer,
    TagGroup,
    ListTile,
    Divider,
    DrawerHeader
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: '' }) private clueId!: string
  @Prop({ default: false }) private visible!: boolean

  @Watch('visible', { immediate: true })
  private onVisibleChanged(visible: boolean) {
    if (visible && TextUtil.isNotEmpty(this.clueId)) {
      this.refreshClueDetail()
    }
  }

  private activeName = 'first'

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private showFollowDrawer = false
  private showExpireDrawer = false
  private showLoseDrawer = false
  private showSuccessDrawer = false
  private showCreateDrawer = false

  private isTagSettingsShow = false

  private clue: IClueData = {
    feiyuClue: {},
    id: '',
    source: '',
    clueStatus: '',
    remark: '',
    productName: '',
    productId: '',
    productImage: '',
    productPrice: 0
  }

  private contactMethods: IDictData[] = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
  private contactList: IContactData[] = []

  private editingRemarks = ''
  private isEditRemarks = false

  private get showAddContact() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.AddContactWay)
  }

  private get feiyuClue(): IFeiyuClueData {
    return this.clue.feiyuClue ?? {}
  }

  private get showStatusRemark() {
    let show = false
    switch (this.clue.clueStatus) {
      case Constant.ClueStatus.Invalid.value:
        show = true
        break
      case Constant.ClueStatus.OrderFailed.value:
        show = true
        break
      case Constant.ClueStatus.OrderSucceeded.value:
        show = true
        break
    }
    return show
  }

  private get statusLabel() {
    let label = ''
    switch (this.clue.clueStatus) {
      case Constant.ClueStatus.Invalid.value:
        label = '失效'
        break
      case Constant.ClueStatus.OrderFailed.value:
        label = '输单'
        break
      case Constant.ClueStatus.OrderSucceeded.value:
        label = '成单'
        break
    }
    return label
  }

  private close() {
    this.$emit('close')
  }

  private get showStatusButtons() {
    return (this.clue.clueStatus === Constant.ClueStatus.Following.value ||
        this.clue.clueStatus === Constant.ClueStatus.WaitFollow.value) &&
      UserModule.abpUserId === this.clue.currentOwnerId
  }

  private deleteContact(index: number) {
    this.$confirm('是否确认删除该联系方式?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    }).then(async() => {
      const tempList: IContactData[] = this.contactList.concat()
      tempList.splice(index, 1)
      await this.requestEditContact(tempList, 'delete')
    })
  }

  private cancelEdit(index: number) {
    this.contactList.splice(index, 1)
  }

  private async saveContact(index: number, editContact: IContactData) {
    const tempList: IContactData[] = this.contactList.concat()
    if (editContact.error) {
      this.$message.warning(editContact.error)
      this.$set(this.contactList, index, {
        ...editContact,
        isEdit: true
      })
      return
    }
    editContact.isEdit = false
    const isNew = editContact.isNew
    editContact.isNew = false
    tempList[index] = editContact
    await this.requestEditContact(tempList, isNew ? 'add' : 'edit')
  }

  private addNewContact() {
    if (this.contactMethods.length === 0) {
      this.$message.warning(Constant.Warnings.InvalidData)
      return
    }
    this.contactList.push({
      value: '',
      isEdit: true,
      isNew: true,
      editValue: '',
      dictId: this.contactMethods[0].id,
      methodName: this.contactMethods[0].name
    })
  }

  private editRemarks() {
    this.isEditRemarks = true
    this.editingRemarks = this.$filterString(this.clue.remark)
  }

  private cancelEditRemarks() {
    this.isEditRemarks = false
    this.editingRemarks = ''
  }

  private saveEditRemarks() {
    this.requestEditRemarks()
  }

  private onClueFollow(followTime: Date, remarks: string, dictName = '') {
    this.clue.clueStatus = Constant.ClueStatus.Following.value
    this.clue.lastFollowAt = DateUtil.dateFormat(followTime)
    this.clue.lastChangeRemark = remarks
    this.clue.lastChangeDict = dictName
    this.$emit('clueFollow', followTime, remarks, dictName)
  }

  private onClueInvalid(remarks: string, dictName = '') {
    this.clue.clueStatus = Constant.ClueStatus.Invalid.value
    this.clue.lastChangeRemark = remarks
    this.clue.lastChangeDict = dictName
    this.$emit('clueInvalid', remarks, dictName)
  }

  private onOrderFail(remarks: string, dictName = '') {
    this.clue.clueStatus = Constant.ClueStatus.OrderFailed.value
    this.clue.lastChangeRemark = remarks
    this.clue.lastChangeDict = dictName
    this.$emit('orderFail', remarks, dictName)
  }

  private onOrderCreate() {
    this.$emit('orderCreate')
  }

  private onOrderSuccess(remarks: string, dictName = '') {
    this.clue.clueStatus = Constant.ClueStatus.OrderSucceeded.value
    this.clue.lastChangeRemark = remarks
    this.clue.lastChangeDict = dictName
    this.$emit('orderSuccess', remarks, dictName)
  }

  private async onTagConfirm(tags: ITagData[]) {
    if (tags.length === 0 || !this.clue.id) {
      return
    }
    const { data } = await SystemApi.setTag(
      TagType.Clue,
      [this.clue.id],
      tags.map(tag => tag.id)
    )
    if (data) {
      this.clue.tags = tags
    }
  }

  private async refreshClueDetail() {
    const { data } = await CustomerApi.getClueDetail(this.clueId)
    if (data) {
      this.clue = data
      this.contactList = []
      ArrayUtil.filter<string>(this.clue.contactWayTitles).forEach((title, index) => {
        this.contactList.push({
          value: ArrayUtil.filter<string>(this.clue.contactWays)[index],
          isEdit: false,
          isNew: false,
          editValue: '',
          dictId: ArrayUtil.filter<string>(this.clue.contactWayDictIds)[index],
          methodName: title
        })
      })
    }
  }

  private async requestEditContact(
    contactList: IContactData[],
    action: 'edit' | 'delete' | 'add' = 'edit'
  ) {
    if (!this.clue.customerId) {
      return
    }
    const { status } = await CustomerApi.editCustomerContact(this.clue.customerId, contactList)
    if (status === 204) {
      this.contactList = contactList
      this.$message.info(this.$t(`clue.${action}ContactSuccess`).toString())
    }
  }

  private async requestEditRemarks() {
    const { status } = await CustomerApi.editClueRemarks(this.clueId, this.editingRemarks)
    if (status === 204) {
      this.isEditRemarks = false
      this.clue.remark = this.editingRemarks
      this.editingRemarks = ''
      this.$message.info(this.$t('clue.editRemarkSuccess').toString())
    }
  }

  private openWecomChat() {
    WecomUtil.instance.openEnterpriseChat({
      userIds: 'alias',
      externalUserIds: 'wmMtZ1XQAAI2psFBI0k1GfDhpd-ig_5A',
      groupName: '',
      chatId: '',
      success: (res) => {
        this.$message.success(res.errMsg)
      },
      fail: (res) => {
        this.$message.error(ObjectUtil.toString(res))
      }
    })
  }
}
