



























































import { Component, Prop } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import Divider from '@/components/Divider/index.vue'
import ListTile from '@/components/ListTile/index.vue'
import { IDictData, IOrderData } from '@/api/types'
import AppService from '@/services/app'
import MarketingApi from '@/api/marketing'
import CustomerApi from '@/api/customer'
import ValidateUtil from '@/utils/validate'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'

@Component({
  name: 'SuccessOrderDrawer',
  components: {
    MobileProductItem,
    ListTile,
    Divider,
    DrawerHeader
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: '',
    required: true
  }) private clueId!: string

  private successOrderCauses: IDictData[] = []
  private currentCauseId = ''
  private remarks = ''

  private searchOrderNo = ''
  private order: IOrderData = { products: [] }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }

  created() {
    this.successOrderCauses = this.$filterArray(AppService.ClueSucceedCauses?.dictItem)
    if (this.successOrderCauses.length > 0) {
      this.currentCauseId = this.successOrderCauses[0].id
    }
  }

  private async querySearchAsync(queryString: string, cb: Function) {
    let results: any = []
    if (!ValidateUtil.isUUID(queryString)) {
      cb(results)
      return
    }
    const { data } = await MarketingApi.orderSearch(queryString)
    if (data) {
      results = [
        {
          ...data,
          value: data.id
        }
      ]
      cb(results)
    } else {
      cb(results)
    }
  }

  private handleSelectCustomer(item: IOrderData) {
    if (!item) {
      return
    }
    this.order = item
  }

  private async confirmSucceedOrder() {
    if (!this.currentCauseId) {
      this.$message.warning(this.$t('clue.selectSuccessOrderCause').toString())
      return
    }
    if (!this.order.id) {
      return
    }
    const { status } = await CustomerApi.clueOrderSucceed(
      this.clueId,
      this.order.id,
      this.currentCauseId,
      this.remarks
    )
    if (status === 204) {
      this.$emit('orderSuccess', this.remarks, this.successOrderCauses.find(dict => {
        return dict.id === this.currentCauseId
      })?.name)
      this.close()
    }
  }
}
