


































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import Divider from '@/components/Divider/index.vue'
import ListTile from '@/components/ListTile/index.vue'
import { IClueData, IDictData, IProductData } from '@/api/types'
import MarketingApi from '@/api/marketing'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import {
  DpBackendActivitiesActivityDto,
  DpBackendSmartContractsSmartContractItemDto
} from '@/proxy/models'
import Constant from '@/constant/constant'
import { ActivityApi, SmartContractApi } from '@/proxy'
import EditableSpan from '@/components/EditableSpan/index.vue'
import { IContactData } from '@/constant/models'
import MobileContactInput from '@/views/wecom/mobile-entry/components/ContactInput/index.vue'
import ArrayUtil from '@/utils/array'
import AppService from '@/services/app'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'CreateOrderDrawer',
  components: {
    MobileContactInput,
    EditableSpan,
    MobileProductItem,
    ListTile,
    Divider,
    DrawerHeader
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: function() {
      return {
        productName: '',
        productPrice: 0,
        productImage: ''
      }
    },
    required: true
  }) private clue!: IClueData

  // 智能合约
  private contractModelId = ''
  private contractList: DpBackendSmartContractsSmartContractItemDto[] = []
  // 商品
  private targetProduct: IProductData = { id: '' }
  // 订单金额
  private orderAmount = 0
  // 客户姓名
  private customerName = ''
  // 客户联系方式
  private contactMethods: IDictData[] = []
  private contactList: IContactData[] = []
  // 线索推荐方
  private providerCommission = 0
  // 活动
  private activityId = ''
  private activityList: DpBackendActivitiesActivityDto[] = []
  private organizerCommission = 0
  // 备注
  private orderRemarks = ''
  // 是否直接成单
  private checkSuccessOrder = false

  private get contract() {
    return this.filterContractList.find(contract => contract.id === this.contractModelId) ?? {}
  }

  private get providerPercentage() {
    let percentage = '0'
    if (this.orderAmount) {
      percentage = (this.providerCommission / this.orderAmount * 100).toFixed(2)
    }
    return `${percentage}%`
  }

  private get organizerPercentage() {
    let percentage = '0'
    if (this.orderAmount) {
      percentage = (this.organizerCommission / this.orderAmount * 100).toFixed(2)
    }
    return `${percentage}%`
  }

  // 线索成交人
  private get clueHandlerName() {
    return UserModule.username
  }

  private get showClueProvider() {
    return this.contract.code === Constant.Contracts.S03001.code ||
      this.contract.code === Constant.Contracts.S04001.code
  }

  private get showOrganizer() {
    return this.contract.code === Constant.Contracts.S02001.code ||
      this.contract.code === Constant.Contracts.S04001.code
  }

  private get filterContractList() {
    if (!this.clue.channelId) {
      return this.contractList.filter(contract => !contract.hasClueChannel)
    }
    return this.contractList.filter(contract => contract.hasClueChannel)
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  @Watch('drawerVisible', { immediate: true })
  private onDrawerVisibleChanged(visible: boolean) {
    if (visible && this.clue.productId) {
      this.targetProduct = {
        id: this.clue.productId,
        image: this.clue.productImage,
        name: this.clue.productName,
        price: this.clue.productPrice
      }
      this.contactMethods = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
      // 填充已有客户数据
      if (this.$filterArray(this.clue.contactWayTitles).length > 0) {
        this.$filterArray(this.clue.contactWays).forEach((way: any, index: string | number) => {
          this.contactList.push({
            value: way,
            dictId: this.$filterArray(this.clue.contactWayDictIds)[index],
            methodName: this.$filterArray(this.clue.contactWayTitles)[index],
            isEdit: false,
            isNew: false,
            editValue: way
          })
        })
      }
      // 无联系方式，初始化一个用于编辑
      if (this.contactMethods.length > 0 && this.contactList.length === 0) {
        this.contactList.push({
          value: '',
          dictId: this.contactMethods[0].id,
          methodName: this.contactMethods[0].name,
          isNew: true
        })
      }
      // 初始化客户姓名
      if (this.clue.customerName) {
        this.customerName = this.clue.customerName
      }
      if (this.clue.productPrice) {
        this.orderAmount = this.clue.productPrice
      }
      this.getContractList()
      this.getActivityList()
    }
  }

  private async getContractList() {
    const { data } = await new SmartContractApi().apiAppSmartContractSelectItemsGet()
    if (data) {
      this.contractList = data
    }
  }

  private async getActivityList() {
    const { data } = await new ActivityApi().apiAppActivityItemsGet()
    if (data) {
      this.activityList = data
    }
  }

  private async confirmCreateOrder() {
    if (!this.clue.id) {
      throw new Error(this.$t('clue.createOrderInvalidClue').toString())
    }
    if (!this.targetProduct.id) {
      throw new Error(this.$t('clue.createOrderInvalidProduct').toString())
    }
    if (!this.clue.customerId) {
      throw new Error(this.$t('clue.createOrderInvalidCustomer').toString())
    }
    if (this.contract.code === Constant.Contracts.S01001.code) {
      if (!this.activityId) {
        this.$message.warning('请选择成交活动')
      }
    }
    const { data } = await MarketingApi.createOrder(
      this.clue.id,
      this.clue.customerId,
      this.customerName,
      this.contactList,
      this.targetProduct.id,
      this.orderAmount,
      this.contract.id,
      this.contract.code,
      {
        clueChannelId: this.clue.channelId,
        clueChannelCommission: this.providerCommission,
        activityId: this.activityId,
        activityChannelId: this.activityList.find(activity => activity.id === this.activityId)?.channelId,
        activityChannelCommission: this.organizerCommission,
        orderRemark: this.orderRemarks,
        isClueStatusOrderSucceeded: this.checkSuccessOrder
      })
    if (data) {
      this.$emit('orderCreate')
      this.close()
    }
  }

  private deleteContact(index: number) {
    this.contactList.splice(index, 1)
  }

  private cancelEdit(index: number) {
    this.contactList.splice(index, 1)
  }

  private async saveContact(index: number, editContact: IContactData) {
    if (editContact.error) {
      this.$message.warning(editContact.error)
      this.$set(this.contactList, index, {
        ...editContact,
        isEdit: true
      })
      return
    }
    editContact.isEdit = false
    editContact.isNew = false
    this.contactList[index] = editContact
  }

  private addNewContact() {
    if (this.contactMethods.length === 0) {
      this.$message.warning(Constant.Warnings.InvalidData)
      return
    }
    this.contactList.push({
      value: '',
      isEdit: true,
      isNew: true,
      editValue: '',
      dictId: this.contactMethods[0].id,
      methodName: this.contactMethods[0].name
    })
  }

  private close() {
    this.$emit('close')
  }

  private onContractChanged() {
    this.targetProduct = {
      id: this.contract.productId,
      name: this.contract.productName,
      price: this.contract.productPrice,
      image: this.contract.productImage
    }
    if (this.contract.productPrice) {
      this.orderAmount = this.contract.productPrice
    }
    if (this.contract.clueChannelCommission) {
      this.providerCommission = this.contract.clueChannelCommission
    }
    if (this.contract.activityChannelCommission) {
      this.organizerCommission = this.contract.activityChannelCommission
    }
  }
}
