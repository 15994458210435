





























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IClueData } from '@/api/types'
import Constant from '@/constant/constant'
import ListTile from '@/components/ListTile/index.vue'
import CreateOrderDrawer from '@/views/wecom/mobile-entry/drawers/create-order/index.vue'
import InvalidOrderDrawer from '@/views/wecom/mobile-entry/drawers/invalid-order/index.vue'
import LoseOrderDrawer from '@/views/wecom/mobile-entry/drawers/lose-order/index.vue'
import SuccessOrderDrawer from '@/views/wecom/mobile-entry/drawers/success-order/index.vue'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import FollowClueDrawer from '@/views/wecom/mobile-entry/drawers/follow-clue/index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'MobileClueItem',
  components: {
    FollowClueDrawer,
    LensSpan,
    MobileProductItem,
    SuccessOrderDrawer,
    LoseOrderDrawer,
    InvalidOrderDrawer,
    CreateOrderDrawer,
    ListTile
  }
})
export default class extends Vue {
  @Prop({ default: false }) showCustomer!: boolean
  @Prop({ default: false }) showRemark!: boolean
  @Prop({
    default: function() {
      return {
        id: ''
      }
    }
  }) private clue!: IClueData

  private showFollowDrawer = false
  private showExpireDrawer = false
  private showLoseDrawer = false
  private showSuccessDrawer = false
  private showCreateDrawer = false

  private handleDrawerClose(done: Function) {
    done()
  }

  private onClueItemClick() {
    this.$emit('onClueItemClick', this.clue)
  }

  private get showStatusButtons() {
    return (this.clue.clueStatus === Constant.ClueStatus.Following.value ||
        this.clue.clueStatus === Constant.ClueStatus.WaitFollow.value) &&
      UserModule.abpUserId === this.clue.currentOwnerId
  }

  private onClueFollow(followTime: Date, remarks: string, dictName = '') {
    this.$emit('clueFollow', followTime, remarks, dictName)
  }

  private onClueInvalid(remarks: string, dictName = '') {
    this.$emit('clueInvalid', remarks, dictName)
  }

  private onOrderFail(remarks: string, dictName = '') {
    this.$emit('orderFail', remarks, dictName)
  }

  private onOrderCreate() {
    this.$emit('orderCreate')
  }

  private onOrderSuccess(remarks: string, dictName = '') {
    this.$emit('orderSuccess', remarks, dictName)
  }
}
