






































import { Component, Prop } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import Divider from '@/components/Divider/index.vue'
import { IDictData } from '@/api/types'
import AppService from '@/services/app'
import CustomerApi from '@/api/customer'
import TextUtil from '@/utils/text'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'

@Component({
  name: 'LoseOrderDrawer',
  components: {
    Divider,
    DrawerHeader
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: '',
    required: true
  }) private clueId!: string

  private loseOrderCauses: IDictData[] = []
  private currentCauseId = ''
  private remarks = ''

  created() {
    this.loseOrderCauses = this.$filterArray(AppService.ClueFailCauses?.dictItem)
    if (this.loseOrderCauses.length > 0) {
      this.currentCauseId = this.loseOrderCauses[0].id
    }
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }

  private async confirmExpireOrder() {
    if (TextUtil.isEmpty(this.clueId)) {
      this.$message.warning(this.$t('system.appError').toString())
      return
    }
    if (TextUtil.isEmpty(this.currentCauseId)) {
      this.$message.warning(this.$t('clue.selectLoseOrderCause').toString())
      return
    }
    const { status } = await CustomerApi.clueOrderFail(
      this.clueId,
      this.currentCauseId,
      this.remarks
    )
    if (status === 204) {
      this.$emit('orderFail', this.remarks, this.loseOrderCauses.find(dict => {
        return dict.id === this.currentCauseId
      })?.name)
      this.close()
    }
  }
}
