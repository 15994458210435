/**
 * @Author: siyukok
 * @Date: 2022/5/6 10:29
 * @Version 1.0
 */
export default class CommonUtil {
  static mobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }
}
