













































import { Component, Prop } from 'vue-property-decorator'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import Divider from '@/components/Divider/index.vue'
import { IDictData } from '@/api/types'
import AppService from '@/services/app'
import CustomerApi from '@/api/customer'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'

@Component({
  name: 'FollowClueDrawer',
  components: {
    Divider,
    DrawerHeader
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: '',
    required: true
  }) private clueId!: string

  private followTime: Date = new Date()
  private followClueWays: IDictData[] = []
  private currentWayId = ''
  private remarks = ''

  created() {
    this.followClueWays = this.$filterArray(AppService.FollowClueWays?.dictItem)
    if (this.followClueWays.length > 0) {
      this.currentWayId = this.followClueWays[0].id
    }
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }

  private async confirmFollow() {
    if (!this.currentWayId) {
      this.$message.warning(this.$t('clue.selectFollowWay').toString())
      return
    }
    if (!this.remarks) {
      this.$message.warning('请输入内容')
      return
    }
    const { status } = await CustomerApi.followClue(
      this.clueId,
      this.followTime,
      this.currentWayId,
      this.remarks
    )
    if (status === 204) {
      this.$emit('clueFollow', this.followTime, this.remarks, this.followClueWays.find(dict => {
        return dict.id === this.currentWayId
      })?.name)
      this.close()
    }
  }
}
