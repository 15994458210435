










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'DrawerHeader'
})
export default class extends Vue {
  @Prop({ default: '' }) private title!: string

  private onCloseClick() {
    this.$emit('on-close-click')
  }
}
