






























































































import { Component, Prop, Watch } from 'vue-property-decorator'
import Divider from '@/components/Divider/index.vue'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import { ICustomerData, IDictData, IProductData } from '@/api/types'
import { IContactData } from '@/constant/models'
import ArrayUtil from '@/utils/array'
import AppService from '@/services/app'
import TextUtil from '@/utils/text'
import Constant from '@/constant/constant'
import CustomerApi from '@/api/customer'
import SelectProductDrawer from '@/views/wecom/mobile-entry/drawers/select-product/index.vue'
import MobileProductItem from '@/views/wecom/mobile-entry/components/ProductItem/index.vue'
import ListTile from '@/components/ListTile/index.vue'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import { DpBackendChannelsChannelDto } from '@/proxy/models'
import { ChannelApi } from '@/proxy'

@Component({
  name: 'CreateClueDrawer',
  components: {
    ListTile,
    MobileProductItem,
    SelectProductDrawer,
    DrawerHeader,
    Divider
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private showProvider!: boolean
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: function(): ICustomerData {
      return {
        contactWayDictIds: [],
        contactWayTitles: [],
        contactWays: [],
        tags: [],
        identity: {
          list: []
        },
        id: ''
      }
    }
  }) private customerData!: ICustomerData

  private byProvider = false
  private channelId = ''
  private channelList: DpBackendChannelsChannelDto[] = []
  private contactMethods: IDictData[] = []

  private customerName = ''
  private contactList: IContactData[] = []

  private targetProduct: IProductData = { id: '' }
  private isSelectProductShow = false

  private remarks = ''
  private createAndWaitAllocate = true

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible && this.customerData) {
      this.initData()
    }
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  initData() {
    this.contactList = []
    this.contactMethods = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
    // 填充已有客户数据
    if (this.customerData.contactWayTitles.length > 0) {
      this.customerData.contactWays.forEach((way, index) => {
        this.contactList.push({
          value: way,
          dictId: this.customerData.contactWayDictIds[index],
          methodName: this.customerData.contactWayTitles[index]
        })
      })
    }
    // 无联系方式，初始化一个用于编辑
    if (this.contactMethods.length > 0 && this.contactList.length === 0) {
      this.contactList.push({
        value: '',
        dictId: this.contactMethods[0].id,
        methodName: this.contactMethods[0].name
      })
    }
    // 初始化客户姓名
    if (this.customerData.name) {
      this.customerName = this.customerData.name
    }
    this.refreshChannelList()
  }

  private close() {
    this.$emit('close')
  }

  private async saveClue() {
    if (!this.targetProduct.id) {
      this.$message.warning('请选择意向商品')
      return
    }
    if (this.contactList.length === 0) {
      this.$message.warning(Constant.Warnings.CreateClueInvalidContact)
      return
    }
    if (TextUtil.isEmpty(this.customerName)) {
      this.$message.warning(Constant.Warnings.CreateClueInvalidName)
      return
    }
    if (this.byProvider && !this.channelId) {
      this.$message.warning(Constant.Warnings.CreateClueInvalidProvider)
      return
    }
    let valid = true
    this.contactList.forEach(contact => {
      const method: IDictData | undefined = this.contactMethods.find(dict => {
        return dict.id === contact.dictId
      })
      if (!method) {
        return
      }
      contact.methodName = method?.name
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const validator: Function = Constant.formRules[method.name]
      validator(null, contact.value, (response: any) => {
        if (response?.message) {
          this.$message.warning(response.message)
          valid = false
        }
      })
    })
    if (!valid) {
      this.$forceUpdate()
      return
    }
    const { status } = await CustomerApi.createClue(
      this.byProvider
        ? Constant.ClueSourceType.BackendNewByChannel.value
        : Constant.ClueSourceType.WeChatCustomer.value,
      this.customerData.id ?? '',
      this.customerName,
      this.targetProduct.id,
      this.remarks,
      this.createAndWaitAllocate,
      this.contactList,
      this.channelId
    )
    if (status !== 204) {
      return
    }
    this.$emit('clueCreate')
    this.close()
  }

  private deleteContact() {
    this.contactList.pop()
  }

  private addNewContact() {
    this.contactList.push({
      value: '',
      isEdit: true,
      isNew: true,
      dictId: this.contactMethods[0].id,
      methodName: this.contactMethods[0].name
    })
  }

  private handleSelectProductClose(done: Function) {
    done()
  }

  private onProductSelect(product: IProductData) {
    this.targetProduct = product
  }

  private async refreshChannelList() {
    const { data } = await new ChannelApi().apiAppChannelSignedListGet()
    if (data) {
      this.channelList = data
    }
  }
}
