





























import { Component, Prop, Watch } from 'vue-property-decorator'
import Divider from '@/components/Divider/index.vue'
import TagGroup from '@/components/TagGroup/index.vue'
import DrawerHeader from '@/views/wecom/mobile-entry/drawers/drawer-header/index.vue'
import { ITagData, ITagGroupData } from '@/api/types'
import SystemApi from '@/api/system'
import ArrayUtil from '@/utils/array'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'

@Component({
  name: 'TagSettingsDrawer',
  components: {
    DrawerHeader,
    Divider,
    TagGroup
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: function() {
      return []
    }
  }) private initTags!: ITagData[]

  private tagGroupList: ITagGroupData[] = []

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.getAllTags()
    }
  }

  private get drawerVisible() {
    return this.visible
  }

  private set drawerVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }

  private onDataUpdate(groupIndex: number, index: number) {
    const targetValue = this.tagGroupList[groupIndex].tags[index]
    this.$set(this.tagGroupList[groupIndex].tags, index, {
      ...targetValue,
      selected: !targetValue.selected
    })
  }

  private onTagsConfirm() {
    const selectTags: ITagData[] = []
    this.tagGroupList.forEach(group => {
      ArrayUtil.filter<ITagData>(group.tags).forEach(tag => {
        if (tag.selected) {
          selectTags.push(tag)
        }
      })
    })
    this.$emit('tagConfirm', selectTags)
    this.close()
  }

  private async getAllTags() {
    const { data } = await SystemApi.getAllTagGroupRead()
    if (data) {
      this.tagGroupList = data
      this.tagGroupList.forEach(group => {
        ArrayUtil.filter<ITagData>(group.tags).forEach(tag => {
          tag.selected = ArrayUtil.filter<ITagData>(this.initTags).some(initTag => {
            return initTag.id === tag.id
          })
        })
      })
    }
  }
}
